import { configService } from 'store/api/configService/index';

const invoiceGroupController = configService.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceGroupsList: build.query({
      query: (params) => ({
        url: '/config-service/admin/v1/invoice-groups',
        params,
      }),
      providesTags: () => [{ type: 'InvoiceGroup' }],
    }),
    getInvoiceGroupItem: build.query({
      query: ({ id }) => ({
        url: `/config-service/admin/v1/invoice-groups/${id}`,
      }),
      providesTags: () => [{ type: 'InvoiceGroup' }],
    }),
    deleteInvoiceGroupItem: build.mutation({
      query: ({ id }) => ({
        url: `/config-service/admin/v1/invoice-groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'InvoiceGroup' }],
    }),
    updateInvoiceGroupItem: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/config-service/admin/v1/invoice-groups${id ? `/${id}` : ''}`,
        method: id ? 'PUT' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'InvoiceGroup' }],
    }),
    getInvoiceGroupsListByEnv: build.query({
      query: ({ env }) => ({
        url: '/config-service/admin/v1/invoice-groups/dictionary',
        params: { env },
      }),
      transformResponse: (response) =>
        response?.reduce(
          (acc, { name, id, ...rest }) => [
            ...acc,
            {
              label: `#${id}: ${name}`,
              value: JSON.stringify({ id, name, ...rest }),
            },
          ],
          [],
        ),
      providesTags: () => [{ type: 'InvoiceGroup' }],
    }),
  }),
});

export const {
  useGetInvoiceGroupsListQuery,
  useGetInvoiceGroupItemQuery,
  useUpdateInvoiceGroupItemMutation,
  useDeleteInvoiceGroupItemMutation,
  useGetInvoiceGroupsListByEnvQuery,
} = invoiceGroupController;
