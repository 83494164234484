import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import { useSearch } from 'hooks/useSearch';

import {
  useDeleteTournamentsCampaignMutation,
  useGetTournamentsCampaignsQuery,
  useGetTournamentsReportQuery,
  useSetTournamentsPaymentMutation,
} from 'store/api/api/tournamentService/campaignController';
import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import { showAlert } from 'store/slices/alert';

import { findPatchPages } from 'utils/pages';

import {
  Assessment,
  Delete,
  Difference,
  Edit,
  Paid,
} from '@mui/icons-material';
import { Stack, TextField, Typography } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import Confirm from 'components/UI/Confirm/Confirm';
import Autocomplete from 'components/UI/Form/Autocomplete';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

import { columns } from 'components/config/campaigns';
import { PAGE_PATH } from 'components/config/pages';

const Campaigns = () => {
  const dispatch = useDispatch();

  const [currency, setCurrency] = useState(null);
  const [reportCurrency, setReportCurrency] = useState(null);
  const [reportItem, setReportItem] = useState(null);
  const [reportConfirm, setReportConfirm] = useState(false);

  const [deleteCampaigns] = useDeleteTournamentsCampaignMutation();
  const [setPayment] = useSetTournamentsPaymentMutation();
  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();

  const { isFetching } = useGetTournamentsReportQuery(
    { id: reportItem?.id, name: reportItem?.name, currency: reportCurrency },
    { skip: !reportItem || !reportCurrency },
  );

  const { searchParams, handleSearch } = useSearch();

  const reportStartDownload = useCallback((currency) => {
    dispatch(
      showAlert({
        type: 'info',
        text: 'Your request has been accepted. The document will be download in a few minutes.',
      }),
    );
    setReportCurrency(currency);
  }, []);

  const handleCurrencyConfirm = useCallback(
    (confirm) => {
      setReportConfirm(false);
      if (confirm) {
        reportStartDownload(currency);
      }
    },
    [currency],
  );

  const handleReport = useCallback((item) => {
    setReportItem(null);
    setReportCurrency(null);
    if (
      dayjs
        .utc(new Date(item.temporalDetails.finishDateTime))
        .diff(dayjs.utc(new Date())) > 0
    ) {
      dispatch(
        showAlert({
          type: 'error',
          text: 'This campaign is active. You will be able to download the results when the campaign is complete.',
        }),
      );
    } else {
      setReportItem(item);

      if (item.monetaryConfig.baseCurrency === 'EUR') {
        reportStartDownload(item.monetaryConfig.baseCurrency);
      } else {
        setCurrency(item.monetaryConfig.baseCurrency);
        setReportConfirm(true);
      }
    }
  }, []);

  const handleAutoPayment = useCallback(async (item) => {
    if (
      dayjs
        .utc(new Date(item.temporalDetails.finishDateTime))
        .diff(dayjs.utc(new Date())) > 0
    ) {
      dispatch(
        showAlert({
          type: 'error',
          text: 'This campaign is active. You will be able to credit the prizes when the campaign is complete.',
        }),
      );
    } else {
      const result = await setPayment(item);
      if (!('error' in result)) {
        dispatch(
          showAlert({
            type: 'success',
            text: 'Your request has been accepted. The prizes will be credited in a few minutes.',
          }),
        );
      }
    }
  }, []);

  return (
    <>
      {isFetching && <FullPageLoader />}
      <TableLayout
        actions={[
          { label: 'Edit', icon: <Edit /> },
          { label: 'Delete', icon: <Delete /> },
          { label: 'Copy', icon: <Difference /> },
          { label: 'Report', icon: <Assessment />, handleClick: handleReport },
          {
            label: 'Retry payment',
            icon: <Paid />,
            handleClick: handleAutoPayment,
          },
        ]}
        columns={columns}
        getDataQuery={useGetTournamentsCampaignsQuery}
        queryConditional={searchParams}
        title="Tournaments"
        deleteItemQuery={deleteCampaigns}
        itemComponentPath={findPatchPages(PAGE_PATH.TOURNAMENTS_CAMPAIGNS_ITEM)}
      >
        <TextField
          name="name"
          label="Search by Name"
          onChange={handleSearch}
          sx={{ alignSelf: 'flex-start', mb: 2 }}
        />
      </TableLayout>
      {reportConfirm && (
        <Confirm
          handleClose={handleCurrencyConfirm}
          content={
            <Stack spacing={3}>
              <Typography>Select the currency of the report</Typography>
              <Autocomplete
                label="Currency"
                name="baseCurrency"
                value={currency}
                handleChange={({ value }) => setCurrency(value)}
                options={dataCurrency}
                loading={currencyLoading}
              />
            </Stack>
          }
        />
      )}
    </>
  );
};

export default Campaigns;
