export const HelperList = [
  {
    name: 'Group name',
    text: 'Name',
  },
  {
    name: 'Environment',
    text: 'The environment on which the operators of this group are located',
  },
  {
    name: 'Operators',
    text: 'List of operators that belong to Invoice Group',
  },
  {
    name: 'Target currency',
    text: 'Affects the currency in which the invoice will be issued, also affects the generated XLSX report',
  },
  {
    name: 'Ignore negative',
    text: 'Affects whether negative GGRs will be taken into account in the invoice',
  },
  {
    name: 'Gaming Tax',
    text: "Gambling Tax is a percentage paid by the provider depending on the partner's regulation/region.",
  },
  {
    name: 'Description',
    text: 'Brief description of the group, important information, etc',
  },
];
