import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import { useToggle } from 'hooks/useToggle';

import { useGetInvoiceGroupsListByEnvQuery } from 'store/api/configService/invoiceGroupController';
import { useGetOperatorListQuery } from 'store/api/hhsKeyCloack/operator';
import {
  useGenerateInvoiceMutation,
  useGetGGRPerProviderQuery,
  useGetProvidersListQuery,
} from 'store/api/hhsKeyCloack/reports';

import { EditItem } from 'utils/EditItem';
import { getENV } from 'utils/getEnv';
import StorageCollection from 'utils/storage/StorageCollection';

import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import DateRange from 'components/UI/DateRange/DateRange';
import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import HelperModal from 'components/UI/HelperModal/HelperModal';
import Subtitle from 'components/UI/Subtitle/Subtitle';
import { HelperList } from 'components/pages/Statistics/GenerateInvoice/helperList';

import { TARGET_CURRENCY } from 'components/config/invoiceGroups';
import { prodEnvs } from 'config/api';

const initialValue = {
  operatorIds: [],
  date_from: null,
  date_to: null,
  gamingTax: null,
  currency: 'EUR',
  licenseFeePerProvider: {},
  ignoreNegative: true,
  useInvoiceGroup: false,
};
const initialValueFromStorage = ({ ignoreNegative, useInvoiceGroup }) => ({
  ...initialValue,
  ignoreNegative,
  useInvoiceGroup,
});
const storage = new StorageCollection('GenerateInvoice', {
  ignoreNegative: true,
  useInvoiceGroup: false,
});
const production = prodEnvs.includes(getENV());
const prepareItemsValue = (itemsValue) => ({
  operatorIds: itemsValue.operatorIds,
  // TODO: remove the format after the backend is ready to receive the timestamp
  dateFrom: dayjs.utc(itemsValue.date_from).format('YYYY-MM-DD'),
  dateTo: dayjs.utc(itemsValue.date_to).format('YYYY-MM-DD'),
  ignoreNegative: itemsValue.ignoreNegative,
  currency: itemsValue.currency,
  gamingTax: itemsValue.gamingTax,
});

const GenerateInvoice = () => {
  const dispatch = useDispatch();

  const [itemsValue, setItemsValue] = useState(
    initialValueFromStorage(storage.getAll()),
  );
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const {
    opened: openHelperModal,
    handleOpen: handleOpenHelperModal,
    handleClose: handleCloseHelperModal,
  } = useToggle();

  const { data: dataSystems, isLoading: systemsLoading } =
    useGetOperatorListQuery(
      production
        ? {
            production,
          }
        : null,
      { skip: !!itemsValue.useInvoiceGroup },
    );
  const { data: dataInvoiceGroups, isLoading: invoiceGroupsLoading } =
    useGetInvoiceGroupsListByEnvQuery(
      { env: getENV() },
      { skip: !itemsValue.useInvoiceGroup || !getENV() },
    );

  const { data: dataProviders } = useGetProvidersListQuery();
  const {
    data: dataGGRPerProvider,
    isFetching,
    isError,
  } = useGetGGRPerProviderQuery(prepareItemsValue(itemsValue), {
    skip:
      !itemsValue.operatorIds?.length ||
      !itemsValue.date_from ||
      !itemsValue.date_to,
  });

  const [generateInvoice] = useGenerateInvoiceMutation();

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleClose: () => {
          setSubmit(false);
          setProcess(false);
        },
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const handleChange = useCallback(
    ({ name, value, type, isCheckValue }) => {
      editItemClass.handleChange({
        name,
        value,
        type,
        isCheckValue,
        errors,
      });
    },
    [errors],
  );

  const handleCreateReport = useCallback(async () => {
    let name;
    if (!itemsValue.useInvoiceGroup) {
      const { label: firstOperator } =
        dataSystems.find(({ value }) => value === itemsValue.operatorIds[0]) ||
        {};
      name = firstOperator;
    } else {
      const { label: invoiceGroup } =
        dataInvoiceGroups.find(({ value }) => {
          const { operators = initialValue.operatorIds } =
            JSON.parse(value) || {};

          return operators[0] === itemsValue.operatorIds[0];
        }) || {};
      name = invoiceGroup;
    }

    name = name?.replace
      ? name.replaceAll(' ', '_').replace('#', '').replace(':', '')
      : 'Invoice';
    const body = prepareItemsValue(itemsValue);

    const result = await generateInvoice({
      ...body,
      licenseFeePerProvider: itemsValue.licenseFeePerProvider,
      name: `${name}_${body.dateFrom}_${body.dateTo}`,
    });

    editItemClass.checkUpdateData(result);
  }, [itemsValue]);

  const handleSubmit = useCallback(
    async () =>
      await editItemClass.handleSubmit({
        itemsValue,
        textFields: ['date_from', 'date_to'],
        multipleFields: ['operatorIds'],
        objectFields: ['licenseFeePerProvider'],
      }),
    [itemsValue],
  );

  useEffect(() => {
    if (dataGGRPerProvider) {
      handleChange({
        name: 'licenseFeePerProvider',
        value: Object.keys(dataGGRPerProvider).reduce(
          (acc, provider) => ({ ...acc, [provider]: '0.00' }),
          {},
        ),
      });
      setErrors([]);
    }
  }, [dataGGRPerProvider]);

  useEffect(() => {
    submit && handleCreateReport();
  }, [submit]);

  return (
    <>
      {(process || isFetching) && <FullPageLoader />}
      <Box
        component="div"
        sx={{
          mb: 2,
          display: 'flex',
          columnGap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="h5" component="h2">
            Generate Invoice
          </Typography>
          <Subtitle subTitle="The section is used by the finance department to create invoices for our partners." />
        </Box>
        <Box>
          <IconButton onClick={handleOpenHelperModal}>
            <InfoOutlined />
          </IconButton>
        </Box>
      </Box>
      <Box component="div" sx={{ display: 'grid', rowGap: 2, mb: 2 }}>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: '1fr 210px',
          }}
        >
          {!itemsValue.useInvoiceGroup && (
            <Autocomplete
              name="operatorIds"
              label="Select operators"
              value={itemsValue.operatorIds}
              handleChange={handleChange}
              options={dataSystems || []}
              error={errors.includes('operatorIds')}
              limitTags={20}
              loading={systemsLoading}
              multiple
              selectFilteredOptions
            />
          )}
          {itemsValue.useInvoiceGroup && (
            <Autocomplete
              name="operatorIds"
              label="Select invoice group"
              handleChange={({ name, value }) => {
                const {
                  operators = initialValue.operatorIds,
                  gamingTax = initialValue.gamingTax,
                  ignoreNegative = initialValue.ignoreNegative,
                  targetCurrency = initialValue.currency,
                } = JSON.parse(value) || {};
                handleChange({ name, value: operators });
                handleChange({ name: 'gamingTax', value: gamingTax });
                handleChange({ name: 'ignoreNegative', value: ignoreNegative });
                handleChange({ name: 'currency', value: targetCurrency });
              }}
              options={dataInvoiceGroups || []}
              error={errors.includes('operatorIds')}
              loading={invoiceGroupsLoading}
            />
          )}
          <FormControlLabel
            label="Use Invoice Groups"
            control={
              <Switch
                color="primary"
                name="useInvoiceGroup"
                checked={itemsValue.useInvoiceGroup}
                onChange={({ target: { name, checked } }) => {
                  handleChange({
                    name,
                    value: checked,
                  });
                  const initialValue = initialValueFromStorage(
                    storage.getAll(),
                  );
                  handleChange({
                    name: 'operatorIds',
                    value: initialValue.operatorIds,
                    isCheckValue: false,
                  });
                  handleChange({
                    name: 'ignoreNegative',
                    value: initialValue.ignoreNegative,
                  });
                  handleChange({
                    name: 'currency',
                    value: initialValue.currency,
                  });
                  storage.add({ useInvoiceGroup: checked });
                }}
              />
            }
            sx={{ m: 0 }}
          />
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: !itemsValue.useInvoiceGroup
                ? '1fr 1fr'
                : '1fr',
            }}
          >
            <DateRange filter={itemsValue} handleChangeFilter={handleChange} />
            {!itemsValue.useInvoiceGroup && (
              <Select
                label="Target currency"
                name="currency"
                value={itemsValue.currency}
                handleChange={handleChange}
                required={false}
                options={TARGET_CURRENCY}
              />
            )}
          </Box>
          {!itemsValue.useInvoiceGroup && (
            <FormControlLabel
              label="Ignore negative"
              control={
                <Switch
                  name="ignoreNegative"
                  color="primary"
                  checked={itemsValue.ignoreNegative}
                  onChange={({ target: { name, checked } }) => {
                    handleChange({
                      name,
                      value: checked,
                    });
                    storage.add({ ignoreNegative: checked });
                  }}
                />
              }
              sx={{ m: 0 }}
            />
          )}
        </Box>
      </Box>
      {!isError &&
        !isFetching &&
        dataProviders &&
        dataGGRPerProvider &&
        itemsValue.operatorIds?.length > 0 && (
          <Box component="div" sx={{ mb: 2 }}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Provider</TableCell>
                  <TableCell>GGR ({itemsValue.currency})</TableCell>
                  <TableCell>License fee %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(dataGGRPerProvider).map((provider) => (
                  <TableRow key={provider}>
                    <TableCell>{dataProviders[provider]}</TableCell>
                    <TableCell>{dataGGRPerProvider[provider]}</TableCell>
                    <TableCell>
                      <Text
                        name={`licenseFeePerProvider[${provider}]`}
                        label="License fee %"
                        value={
                          itemsValue.licenseFeePerProvider[provider] ?? '0.00'
                        }
                        onChange={({ target: { name, value } }) =>
                          handleChange({
                            name,
                            value,
                            type: 'number',
                          })
                        }
                        error={errors.includes(
                          `licenseFeePerProvider[${provider}]`,
                        )}
                        disabled={dataGGRPerProvider[provider] === 0}
                        sx={{ width: 130 }}
                        required
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!dataGGRPerProvider || isError}
        >
          Download invoice
        </Button>
      </Box>
      {openHelperModal && (
        <HelperModal
          handleClose={handleCloseHelperModal}
          helperList={HelperList}
        />
      )}
    </>
  );
};

export default GenerateInvoice;
