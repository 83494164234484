import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { findPatchPages } from 'utils/pages';

import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import NotFound from 'components/pages/NotFound/NotFound';

import { BASE_PATH, PAGE_PATH, Pages } from 'components/config/pages';

const Content = () => (
  <Routes>
    {Object.keys(Pages).map((key) => (
      <Route
        element={<PrivateRoute Component={Pages[key].element} />}
        path={Pages[key].path}
        key={key}
      />
    ))}
    <Route
      path={BASE_PATH}
      element={<Navigate to={findPatchPages(PAGE_PATH.HOME)} replace />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default Content;
