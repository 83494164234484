import _ from 'lodash';

export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

export const isNotEmpty = (value) =>
  Boolean(
    Array.isArray(value)
      ? value.length
      : typeof value === 'boolean'
        ? true
        : _.isObject(value)
          ? true
          : value?.toString()?.length,
  );

export const checkByType = (value, type) =>
  type === 'number' ? isNumeric(value) : typeof value === type;

export const checkValue = ({
  value,
  name,
  setErrors,
  errors,
  type,
  maxLength,
  required = true,
}) => {
  let isValidateValue = true;
  if (type && value?.length > 0) {
    isValidateValue = checkByType(value, type);
  }
  if (required && isValidateValue) {
    isValidateValue = isValidateValue && isNotEmpty(value);
  }
  if (maxLength && isValidateValue) {
    isValidateValue = isValidateValue && maxLength > value?.length;
  }

  if (setErrors && errors && name) {
    if (isValidateValue && errors.indexOf(name) !== -1) {
      setErrors((prevState) => {
        prevState.splice(errors.indexOf(name), 1);
        return prevState;
      });
    } else if (!isValidateValue && errors.indexOf(name) === -1) {
      setErrors((prevState) => [...prevState, name]);
    }
  }

  return isValidateValue;
};
