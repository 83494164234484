import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

const operator = hhsKeyCloack.injectEndpoints({
  endpoints: (build) => ({
    getOperatorList: build.query({
      query: (params) => ({
        url: '/operator/v1/list',
        params,
      }),
      transformResponse: (response) =>
        response?.map(({ id, title }) => ({
          label: `#${id}: ${title}`,
          value: id,
        })),
    }),
  }),
});

export const { useGetOperatorListQuery } = operator;
